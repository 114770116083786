import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    
    constructor(
        private userService: UserService
    ) {}
    
    intercept(request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        let exempt = true;
        let auth = environment.app_token;
        let token = 'd';
        
        if(request.url.includes(environment.api_url)) {
            exempt = false;
        }
        
        if (!exempt) {
            token = this.userService.dealer.token;

            request = request.clone({
                setHeaders: { Authorization: `Basic ${auth}` },
                params: request.params.set('token', token),
            });
        }

        return next.handle(request);
    }
}
