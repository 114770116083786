export const environment = {
    production: false,
    api_url: 'https://api.advantagecomplete.ca/api',
    app_token: 'zHeRDGkDTgmNdD65BqSIYag0a3tezfUasd8rra7AJQ3FLf38tm1Auu732PK1NYYg',
    pusher: {
        key: '33df1cdd29d26a7fe961',
        cluster: 'us2',
    },
    firebase: {
        apiKey: 'AIzaSyCJqShwpjNjQy9zXV_qGIHFFX4AWj9xWEU',
        authDomain: 'advc-baa72.firebaseapp.com',
        projectId: 'advc-baa72',
        storageBucket: 'advc-baa72.appspot.com',
        messagingSenderId: '858627872207',
        appId: '1:858627872207:web:70559280bc3dc967682eba'
    }
};
