import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alerts.service';
import { ConversationService } from 'src/app/services/conversation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'comp-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss'],
})
export class AlertComponent implements OnInit {
    // Alerts
    @Input() alerts: any = [];

    constructor(
        private alertService: AlertService,
        private userService: UserService,
        private router: Router,
        private modalCtrl: ModalController
    ) {
        this.onAlertClick = this.onAlertClick.bind(this);
        this.onViewAllClick = this.onViewAllClick.bind(this);
    }

    ngOnInit(): void {
        this.getAlerts();
    }

    // Alerts
    getAlerts(): void {
        this.alertService.getAlerts().subscribe((resp) => {
            this.alerts = resp['alerts'];
        })
    }

    // Alert Click
    onAlertClick(alert): void {
        let id = alert.path.split('?id=')

        this.alertService.setAlertStatus(alert.id).subscribe((resp) => {
            // Dismiss Modal
            this.modalCtrl.dismiss();

            // Load Alert
            this.router.navigate(['redirect'], { 
                queryParams : { id: id[1], storeChange: alert.dealer_id}
            });
        });
    }

    onViewAllClick(): void {
        this.modalCtrl.dismiss();

        this.router.navigate(['alerts']);
    }
}
