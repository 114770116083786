import { Component, EventEmitter, Output,Injectable,OnInit } from '@angular/core';
import { ContextMenuService } from './context-menu.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  visible = false;
  position = { x: 0, y: 0 };
  public ToggleRightClick=false;

  constructor(private contextMenuService: ContextMenuService) {}
  @Output() select = new EventEmitter<void>();

  ngOnInit() {
    this.contextMenuService.contextMenuState$.subscribe(state => {
      this.visible = state.visible;
      this.position = { x: state.x, y: state.y };
    });
  }
  show(x: number, y: number): void {
    this.position = { x, y};
    this.visible = true;
  }
  closeMenu() {
    this.contextMenuService.closeContextMenu();
  }

  deleteMessage() {
    // Implement delete message functionality
    this.closeMenu();
  }
  onSelect(): void {
    this.ToggleRightClick=true;
    this.select.emit();
    this.deleteMessage();
  }
}
