import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ConversationService } from 'src/app/services/conversation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'comp-dealer-selector',
    templateUrl: './dealer-selector.component.html',
    styleUrls: ['./dealer-selector.component.scss'],
})
export class DealerSelectorComponent implements OnInit {
    // Conversation
    @Input() dealers: any = [];

    // Dealer
    dealer: any = {};

    constructor(
        private userService: UserService,
        private router: Router,
        private modalCtrl: ModalController
    ) {}

    ngOnInit(): void {
        // Dealer List
        this.dealers = this.userService.dealers;

        // Selected Dealer
        this.dealer = this.userService.dealer;
    }

    /**
     * [onDealerClick - Sets the new dealers store]
     * @param   {store}
     * @returns {void}
     */
    onDealerClick(store): void {
        // Check If Dealers Selected
        if(store.id == this.dealer.id) {
            return;
        }

        // Assign Store
        this.dealer = store;
        this.userService.dealer = store;

        // Update Dealer Viewing
        this.userService.setUserDealer(this.dealer)
            .subscribe((resp) => {
                this.modalCtrl.dismiss();
                this.router.navigate(['redirect'], { queryParams: { storeChange: store.id}});
            });
    }
}
