import { Component, ViewChild, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { PusherService } from './services/pusher.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { ModalController } from '@ionic/angular';

import { DealerSelectorComponent } from './components/dealer-selector/dealer-selector.component';
import { AlertComponent } from './components/alerts/alerts.component';
import { Title } from '@angular/platform-browser';
import { ContextMenuComponent } from './components/conversations/context-menu/context-menu.component';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent {
    @ViewChild(ContextMenuComponent) contextMenu: ContextMenuComponent;
    user: any;
    isReady: boolean = false;
    uuid:any = '';
    show: any = { profile: false };
    isMenuOpen: boolean = false;
    
    // Alerts
    _showAlertBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    _showAlertBannerText: BehaviorSubject<string> = new BehaviorSubject<string>('');
    _showAlertUnread: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // Alert Observables
    get showAlertBanner(): Observable<boolean> {
        return this._showAlertBanner.asObservable();
    }

    get showAlertBannerText(): Observable<string> {
        return this._showAlertBannerText.asObservable();
    }

    constructor(
        private router: Router, 
        private route: ActivatedRoute,
        private authService: AuthService,
        private userService: UserService,
        private pusher: PusherService,
        private modalCtrl: ModalController,
        private titleService: Title,
    ) {
        this.getAuthenticatedUser();
    }
    
    ngOnInit(): void {
        
    }

    getQueryParams() {
        this.uuid = this.route
    }

    async getAuthenticatedUser() {
        let user = JSON.parse(localStorage.getItem('user')!);
        
        if(user) {
            await this.userService.getUserDetails(user.uid).subscribe((response) => {
                console.log(user.first_name);
                this.userService.setUserAccount(response);
                this.user = this.userService;
                this.isReady = true;
                
                // Subscribe to alerts
                this.setAlertSubscription();

                // Set User Alerts
                if(this.user.alertCount > 0) {
                    this.titleService.setTitle(
                        `${this.user.dealer.name} (${this.user.alertCount}) | ` + this.titleService.getTitle()
                    );
                } else {
                    this.titleService.setTitle(
                        `${this.user.dealer.name} | ` + this.titleService.getTitle()
                    );
                }

            });
        } else {
            this.isReady = true;
        }
    }

    /**
     * [setAlertSubscription - Subscribe to pusher socket]
     * @returns {void}
     */
    setAlertSubscription(): void {
        this.pusher.subscribe('alert.ADV' + this.user.id);

        this.pusher.channel.bind('message', (data) => {
            if(data.store == this.userService.dealer.id) {
                this._showAlertBanner.next(true);
                this._showAlertBannerText.next(data.message);
                
                this.getAuthenticatedUser();
                
                setTimeout(() => {
                    this._showAlertBanner.next(false);
                    this._showAlertBannerText.next('');
                }, 4000);
            }
        });
    }

    async onDealerSelectorClick() {
        const modal = await this.modalCtrl.create({
            component: DealerSelectorComponent,
            cssClass: 'app-modal-selector'
        });

        modal.present();    
    }

    async onAlertClick() {
        const modal = await this.modalCtrl.create({
            component: AlertComponent,
            cssClass: 'app-modal-alerts'
        });

        modal.present();    
    }

    /**
     * [onLogoutClick - Logs the current user out]
     * @returns {void}
     */
    onLogoutClick(): void {
        this.authService.logout();

        // Reset User
        this.userService.id = 0;
        this.show.profile = false;
    }
    onHide(): void{
        this.contextMenu.deleteMessage();

    }
}
