// context-menu.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  private contextMenuState = new Subject<{ visible: boolean; x: number; y: number }>();
  contextMenuState$ = this.contextMenuState.asObservable();

  openContextMenu(x: number, y: number) {
    this.contextMenuState.next({ visible: true, x, y });
  }

  closeContextMenu() {
    this.contextMenuState.next({ visible: false, x: 0, y: 0 });
  }
}
