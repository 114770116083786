import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare const Pusher: any;

@Injectable({
    providedIn: 'root',
})
export class PusherService {
    pusher: any;
    channel: any;

    constructor() {
        // Initialize Pusher
        this.pusher = new Pusher(environment.pusher.key, {
            cluster: environment.pusher.cluster,
            encrypted: true,
        });
    }

    // Sets the Pusher channel
    subscribe(name): void {
        this.channel = this.pusher.subscribe(name);
    }
}
