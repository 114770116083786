import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    // User Unique ID
    id: number = 0;

    // Firebase Key
    key: string = '';
    admin: number = 0;
    reference: string = '';

    // Email / Username
    email: string = '';

    // Name
    firstName: string = '';
    lastName: string = '';
    fullName: string = '';

    // Personal Number
    phone: string = '';

    // Alert Counts
    alertCount: number = 0;
    storeAlertCount: number = 0;

    // Authorized Stores
    dealers: Array<any> = [];

    // Active Dealer
    dealer: any = {};
    
    // User
    user: any = {};

    // Viewing
    viewing: any;

    constructor(private http: HttpClient, private titleService: Title) {}

    /**
     * [setUserAccount - Sets the authenticated users data]
     * @returns {void}
     */
    setUserAccount(response: any): void {
        this.id = response.user.id;
        this.firstName = response.user.first_name;
        this.lastName = response.user.last_name;
        this.email = response.user.email;
        this.user = response.user;
        
        // References
        this.reference = response.user.reference;
        this.key = response.user.token;

        // Mark Admin
        if(response.user.admin && response.user.admin == 1) {
            this.admin = 1;
        }

        // Conversation Viewing
        if(response.user.viewing) {
            this.viewing = response.user.viewing;
        }

        // Alerts
        this.alertCount = response.user.alert_count;
        this.storeAlertCount = response.user.store_alert_count;

        // Dealers
        this.dealers = response.dealers;
        this.dealer = response.dealer;
    }

    /**
     * [setUserAlerts - Sets the authenticated users alert data]
     * @param response 
     */
    setUserAlerts(response: any): void {
        this.alertCount = response.user.alert_count;
        this.storeAlertCount = response.user.store_alert_count;

        if(this.alertCount > 0) {
            this.titleService.setTitle(
                `${this.dealer.name} (${this.alertCount}) | ` + this.titleService.getTitle()
            );
        } else {
            this.titleService.setTitle(
                `${this.dealer.name} | ` + this.titleService.getTitle()
            );
        }
    }

    /**
     * [getUserDetails - Gets users account details]
     * @returns {Observable<any>}
     */
    getUserDetails(uuid: string, token: string = ''): Observable<any> {
        let params = new HttpParams();
        if(token && token != '') {
            params = params.set('auth', token);
        }
        
        return this.http.get<Array<Object>>(
            `${environment.api_url}/accounts/${uuid}`, {//${uuid}
                params: params
            }
        );
    }
    /**
     * [getUserDetails - Gets users account details]
     * @returns {Observable<any>}
     */
    setUserDealer(dealer): Observable<any> {
        let data = {
            dealer: dealer.id
        }

        return this.http.post<Object>(
            `${environment.api_url}/accounts/${this.key}/dealer`,
            data
        );
    }
}
