import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    constructor(private http: HttpClient) {}

    // Get Alert List
    getAlerts(filters?) {
        let params = new HttpParams();
        
        if(filters) {
            params = params.set('read', filters.read);
            params = params.set('page', filters.page);
        } else {
            params = params.set('read', 0);
        }
        
        return this.http.get<Array<Object>>(
            `${environment.api_url}/alerts`, {
                params: params
            }
        );
    }

    // Set Alert Status
    setAlertStatus(id) {
        return this.http.post<Object>(
            `${environment.api_url}/alert/${id}/read`, {}
        );
    }
}
