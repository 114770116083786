import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// Pages
import { LoginPage } from './pages/auth/login/login.page';
import { ConversationPage } from './pages/conversations/conversations.page';
import { LeadsPage } from './pages/leads/leads.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { CustomersPage } from './pages/customers/customers.page';
import { CreateUserPage } from './pages/create-user/create-user.page';
import { SettingsPage } from './pages/settings/settings.page';

import { AuthGuard } from './guards/auth.guard'

const routes: Routes = [
    { 
        path: '', 
        redirectTo: 'conversations', 
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./pages/auth/login/login.module').then(
                (m) => m.LoginPageModule
            ),
    },
    {
        path: 'conversations',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/conversations/conversations.module').then(
                (m) => m.ConversationPageModule
            ),
    },
    {
        path: 'leads',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/leads/leads.module').then((m) => m.LeadsPageModule),
    },
    // {
    //   path: 'dashboard',
    //   loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule)
    // },
    {
        path: 'customers',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/customers/customers.module').then(
                (m) => m.CustomersPageModule
            ),
    },
    {
        path: 'createuser',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/create-user/create-user.module').then(
                (m) => m.CreatePageModule
            ), 
    },
    {
        path: 'settings/:page',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/settings/settings.module').then(
                (m) => m.SettingsPageModule
            ),
    },
    {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/users/users.module').then((m) => m.UsersPageModule),
    },
    {
        path: 'alerts',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/alerts/alerts.module').then((m) => m.AlertPageModule),
    },
    {
        path: 'redirect',
        loadChildren: () =>
            import('./pages/redirect/redirect.module').then(
                (m) => m.RedirectPageModule
            ),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
